<template>
    <!-- 客户管理》客户价格刷选 -->
    <div id="app_user_price_search">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
            </div>
            <div class="head_top_title">客户价格刷选</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row id="user_price_search_id" style="padding:10px 0;">
                <el-form :model="form_data" :inline="true"  style="margin-bottom: 12px;">
                    <el-form-item label="日期">
                        <el-date-picker
                            v-model="form_data.daytime"
                            type="date"
                            placeholder="选择日期" size="mini" style="width:130px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="发送量类型">
                        <el-select v-model="form_data.numType" size="mini" style="width:90px;"> 
                            <el-option label="全部" value="-1"></el-option>
                            <el-option label="上月" value="0"></el-option>
                            <el-option label="本月" value="1"></el-option>
                            <el-option label="上上月" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="最低发送量" v-show="form_data.numType != '-1'">
                        <el-input v-model="form_data.sendNum" placeholder="请填写最低发送量" size="mini" style="width:90px;"
                             oninput="value=value.replace(/[^\d.]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="getData()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-form_height)+'px')}">
                
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" :lazy="true">
                    <el-table-column  fixed  prop="cpid" label="账号" min-width="80"  align="center" sortable> </el-table-column>
                    <el-table-column  prop="company_name" label="公司名" min-width="160"  align="center" sortable> </el-table-column>
                    <el-table-column  prop="oper" label="运营商" min-width="80"  align="center" sortable> </el-table-column>
                    <el-table-column  prop="yld_level" label="客户类型" min-width="80"  align="center" sortable> </el-table-column>
                    <el-table-column  prop="price" label="价格(分)" min-width="80"  align="center" sortable> </el-table-column>
                    <el-table-column  prop="numAll" label="发送量" min-width="80"  align="center" sortable> </el-table-column>
                </el-table>
            
            </el-row>

            
            

        </el-row>

        

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';

//import axios from "axios";
export default {

    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度
            form_height:0,

            form_data:{
                daytime:new Date(),//日期
                numType:"-1",//发送量类型，-1：全部 0：上月 1：本月 2：上上月
                sendNum:"0",//最低发送量，当type不等于-1时出现，
            },

            tableData:[],//数据
            
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        this.form_data = this.$options.data().form_data;//单个恢复初始化
        this.tableData = this.$options.data().tableData;//单个恢复初始化
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.$nextTick(function(){
                //获取分页的高度
                if(document.getElementById('user_price_search_id')){
                    this.form_height = document.getElementById('user_price_search_id').clientHeight;
                    console.log("form_height:"+this.form_height);
                }
            });

        },

        //查询
        getData(){
            //获取分页的高度
            if(document.getElementById('user_price_search_id')){
                this.form_height = document.getElementById('user_price_search_id').clientHeight;
            }
            if(API.isEmtry(this.form_data.daytime)){
                this.$message.error("请填写日期");
                return;
            }
            var daytime = dateFtt(this.form_data.daytime,"yyyyMMdd");
            if(this.form_data.numType == "-1" && API.isEmtry(this.form_data.sendNum)){
                this.$message.error("请填写最低发送量");
                return;
            }
            //请求接口
            API.UserPriceServlet({
                param: "userPriceSearch",
                daytime:daytime,
                numType:this.form_data.numType,//发送量类型，-1：全部 0：上月 1：本月 2：上上月
                sendNum:this.form_data.sendNum,//最低发送量，当type不等于-1时出现，
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;
                } 
            });
        },
        
        //返回
        goBack() {
            API.router_to("/user_index");
        }
    }
};

</script>

<style scoped>

</style>